.modalContent {
    z-index: 2300 !important;
}
.modalContent .modalHeader{
    border-bottom: none;
}
.modalContent .modalHeader .modalBtn {
    font-size: 10px;
}
.modalContent .modalBody {
    padding: 30px 0;
}
.modalBody .modalH3 {
    font-size: 16px;
    color: #3B3B3B;
    text-align: center;
    line-height: 150%;
    padding: 0px 12px;
}
.modalContent .modalFooter {
    padding: 0 20px 20px 20px;
    border-top: none;
}

.modalContent .modalFooter .modalRound {
    font-size: 14px;
    color: #fff;
    border-radius: 100px;
    background-color: #ef2929;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent .modalConfirmFooter {
    padding: 0 20px 20px 20px;
    border-top: none;
    flex-wrap: nowrap;
}
.modalContent .modalConfirmFooter .modalRound {
    font-size: 14px;
    color: #fff;
    border-radius: 100px;
    background-color: #ef2929;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalContent .modalConfirmFooter .modalRound:hover{
    color: var(--bs-btn-hover-color) !important;
    background-color: var(--bs-btn-hover-bg) !important;
    border-color: var(--bs-btn-hover-border-color) !important;
}
.modalContent .modalConfirmFooter .modalCancel {
    font-size: 14px;
    color: #3b3b3b;
    border-radius: 100px;
    border: 1px solid #8b8b8b;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.backdrop {
    z-index: 2000 !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
}